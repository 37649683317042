.ant-spin-dot-item {
  background-color: $white;
}

.ant-spin {
  margin-right: 7px !important;
}

.ant-select-dropdown {
  @include themify {
    background-color: themed('background-menu-opacity-20');
    backdrop-filter: blur(25px);
    border-radius: 8px;

    div {
      .rc-virtual-list {
        background-color: transparent;
        position: relative !important;
        z-index: 1000;
      }
    }
  }
}

.ant-select-item-option-active {
  @include themify {
    background-color: themed('background-menu-opacity-20') !important;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $white;

  div {
    color: $black !important;
  }

  &:hover {
    div {
      color: $white !important;
    }
  }
}

.ant-select-item-option {
  div {
    background-color: transparent;
    color: $white;
  }
}

.ant-notification {
  @include themify {
    background-color: $transparent;

    div {
      background-color: $transparent;

      .ant-notification-notice {
        background-color: $white-20;
        backdrop-filter: blur(15px);
        border-radius: 30px;

        .ant-notification-notice-content {
          background-color: $transparent;

          .ant-notification-notice-with-icon {
            background-color: $transparent;

            .ant-notification-notice-message {
              background-color: $transparent;
              color: $white;
            }
          }
        }

        .ant-notification-notice-close {
          color: $white;
        }
      }
    }
  }
}

.ant-steps {
  padding: 26px 50px;

  @media (min-width: 992px) {
    padding: 26px 250px;
    height: max-content;
    align-items: center;
  }

  .ant-steps-item {
    width: 33.33%;
    height: 100%;
    align-items: center;

    &::before {
      background-color: $transparent;
    }

    &::after {
      border-color: $white;
      border-radius: 2px;
      border-width: 2px;
      left: 95%;
      right: 95%;
    }

    &-container {
      display: inline-flex;
      align-items: center;

      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 14px;
        }
      }
    }

    &-process {
      .ant-steps-item-icon {
        background-color: $white;
        border: none;

        .ant-steps-icon {
          color: $base-color-1;
        }
      }

      .ant-steps-item-content {
        width: 160px;

        .ant-steps-item-title {
          overflow: visible;
          white-space: break-spaces;
          color: $white;
          line-height: normal;
        }
      }
    }

    &-wait {
      .ant-steps-item-icon {
        background-color: $white-20;
        border: none;

        .ant-steps-icon {
          color: $white-60;
        }
      }

      .ant-steps-item-content {
        width: 160px;

        .ant-steps-item-title {
          color: $grey-1;
          overflow: visible;
          white-space: break-spaces;
          line-height: normal;
        }
      }
    }

    &-finish {
      &::after {
        border-color: $state-color-3;
      }

      .ant-steps-item-icon {
        background-color: $state-color-3;
        border: none;

        .ant-steps-icon {
          color: $black;
          font-weight: bold;
        }
      }

      .ant-steps-item-content {
        width: 160px;

        .ant-steps-item-title {
          color: $state-color-3;
          overflow: visible;
          white-space: break-spaces;
          line-height: normal;
        }
      }
    }
  }
}

.ant-upload {
  background-color: $base-color-3;
  border: none !important;
  border-radius: 12px !important;

  .ant-upload-drag-container {
    padding: 0 60px 30px 60px;

    .ant-upload-drag-icon {
      margin-top: 15px;
    }

    .ant-upload-text {
      font-size: 18px !important;
      line-height: 24px;
      font-weight: 400 !important;
      margin-bottom: 20px !important;
    }

    .ant-upload-hint {
      font-size: 10px !important;
      font-weight: 300 !important;
      line-height: 15px;
      color: $black !important;
    }
  }
}

.ant-upload-list-item-list-type-text {
  color: $white;

  &:hover {
    background: $white-20;
    color: $black;
    border-radius: 8px;
  }

  .ant-upload-list-item-info {
    &:hover {
      background-color: $white-20;
      border-radius: 8px;
    }
  }
}

.ant-tabs-top {
  .ant-tabs-nav {
    &::before {
      border-bottom: 2px solid $white-20;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          color: $white-60;
          font-size: 16px;
          font-weight: 500px;
        }

        &-active {
          .ant-tabs-tab-btn {
            color: $white;
          }
        }
      }

      .ant-tabs-ink-bar-animated {
        background: $white;
      }
    }
  }
}

.update-settings-form {
  .ant-input-number {
    border-radius: 20px;
    background-color: $white-40;
    color: $white;

    .ant-input-number-handler-wrap {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      background-color: $white-60;
    }
  }
}

.fp_tst_rslt .ant-table table {
  table-layout: fixed !important;
  background: transparent !important;
  border-radius: 12px !important;
}

.fp_tst_rslt .ant-table-thead>tr>th {
  padding: 10px !important;
  background: $white-20 !important;
  color: $white !important;
  text-align: center;
  font-weight: bolder !important;

  &.Pay {
    border-bottom: 1px solid $linear-gradient-green;
  }

  &.Professional {
    border-bottom: 1px solid $linear-gradient-pink;
  }

  &.Enterprise {
    border-bottom: 1px solid $linear-gradient-yellow;
  }
}

.fp_tst_rslt {
  .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
}

.fp_tst_rslt .ant-table-tbody>tr.ant-table-row>td,
.ant-table-tbody>tr>td.ant-table-cell-row {
  background-color: $black-40 !important;
  color: $white !important;
  padding: 10px !important;
  height: 150px !important;
  cursor: pointer !important;
}

.fp_tst_rslt .ant-table-tbody>tr>td {
  overflow-wrap: normal !important;
  &.Pay {
    border-bottom: 1px solid $linear-gradient-green;
  }

  &.Professional {
    border-bottom: 1px solid $linear-gradient-pink;
  }

  &.Enterprise {
    border-bottom: 1px solid $linear-gradient-yellow;
  }
}

.fp_tst_rslt_modal_table .ant-table-tbody>tr.ant-table-row>td,
.ant-table-tbody>tr>td.ant-table-cell-row {
  background: $black-40 !important;
  color: $white !important;
  cursor: pointer !important;
  padding: 0;
  border: 1px solid $white-40;
}

.fp_tst_rslt_modal_table .ant-table table {
  table-layout: fixed !important;
  background-color: transparent !important;
  border-radius: 12px !important;
}

.fp_tst_rslt_modal_table .ant-table-thead>tr>th {
  padding: 4px !important;
  background: #716565 !important;
  color: $white !important;
  text-align: center;
  border: 1px solid $white-40;

  &.Pay {
    border-bottom: 1px solid $linear-gradient-green;
  }

  &.Professional {
    border-bottom: 1px solid $linear-gradient-pink;
  }

  &.Enterprise {
    border-bottom: 1px solid $linear-gradient-yellow;
  }
}

.ant-radio-disabled + span {
  color: $white-40 !important;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #de877d !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #de877d;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #de877d ;
}

.ant-radio-checked .ant-radio-inner:focus{
  border-color: #de877d;
}