.fp_new_project {
  &_btn {
    float: right;
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &_col {
    @media (max-width: 992px) and (min-width: 576px) {
      width: 100%;
      margin-top: 20px;
    }
    &_col {
      text-align: right !important;
      @media (max-width: 576px) {
        width: 100%;
        margin-top: 20px;
      }
      &_testcase {
        padding-right: 25px !important;
      }
    }
  }
}

.fp_testcase_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fp_prjct_cncl_btn{
  display: flex;
  justify-content: flex-end !important;
}

.fp-ctr-button {
  // font-weight: $weight-700 !important;
  border-radius: 12px !important;
  height: 100px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(67, 0, 52, 0.05);
  transition: none;
  border: 1.5px solid;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px !important;

  &-device-button {
    // background: $transparent;
    border-color: $transparent !important;
    width: 100% !important;
    outline: "none";
    .ant-btn {
      height: 50 !important;
    }

    span {
      color: $white !important;
    }

    &:hover {
      // background: $landing-color-5 !important;
      background: $linear-gradient-purple-green;
      color: $white !important;
    }

    &n:hover,
    &:focus {
      color: $white !important;
    }

    &:disabled {
      color: $white !important;

      span {
        color: $white !important;
      }

      border-color: $white !important;
      background-color: $transparent !important;
      opacity: 0.5;
    }
  }
}

.ctr-dowload-btn {
  width: 20px !important;
  margin-bottom: 10px;
}