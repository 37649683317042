.fp_onboard_page {
  &_container {
    padding: 25px 40px;
    border-radius: 12px;
    background: $white-10;
    margin: 30px;
    min-height: 80vh;
    overflow: auto;
    color: $white !important;

    p {
      font-weight: 300 !important;
    }

    &_collapse {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 850px;

      li {
        margin-bottom: 12px;
        color: $white;
        font-weight: 300 !important;

        a {
          color: $green !important;
          font-weight: 700;
        }
      }

      .ant-collapse {
        border-radius: 12px;
        background: $white-10 !important;
        backdrop-filter: blur(25px);
        border: 1.5px solid $white-20 !important;
        
        .ant-collapse-item {
          border: none !important;

          .ant-collapse-header {
            font-weight: 500 !important;
            color: $white !important;
          }
  
          .ant-collapse-content {
            background: transparent !important;
            border-radius: 0 0 12px 12px;
            border-top: 1px solid $white-20 !important;

            .ant-collapse-content-box {
              text-align: center;

              img {
                margin: 12px 0 20px;              
              }
            }
          }
        }
      }
    }
  }

  .ant-typography {
    color: $white !important;
  }
}