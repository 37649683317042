.mn-hdr {
  margin: 0 0 24px !important;
}

.frm-cntnr {
  width: 520px;

  .fp_rqg_frm {
    width: 520px;
    padding: 9px 50px 18px 50px;
    margin-left: 0;

    .frm-txt {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.01em;
      color: $white;
      margin: 0 0 12px;
    }

    .frm-sbmt-btn {
      width: 131px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 140%;
      text-align: center;
      letter-spacing: 0.01em;
      color: $black-gray !important;
      background: linear-gradient(90deg,
          #c9def4 0%,
          #e4bbc0 50.52%,
          #b8a4c9 100%);
      border-radius: 20px;
      border-color: transparent;
      box-shadow: 0px 4px 4px rgba(67, 0, 52, 0.05);
    }
  }
}

.fp_qa_service .ant-modal-content {
  left: 25% !important;
  top: 43% !important;
  position: relative !important;
  width: 448px !important;
  border-top: 13px solid #de4359 !important;

  .fp_qa_service .ant-modal-body {
    .fp_qa_service .mdl-mn-txt {
      font-weight: 700 !important;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: $white !important;
    }

    .fp_qa_service .mdl-txt {
      margin: 8px 0 0 0;
    }
  }
}

.fp_qa_row {
  margin-top: 20px !important;
}

.fp_qa_title {
  color: $white !important;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: $white !important;
}