.fp_rqg {
  &_outer-view {
    margin-top: 20px;
  }

  &_inner-view {
    gap: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &_disp-inline {
    display: flex;
  }
}

.fp_tcc {
  &-ttl {
    color: $white;
    font-size: 25px;
    margin-block: 0;
  }

  &-sub {
    color: $grey-1 !important;
    margin-bottom: 10px;
  }

  &-btn {
    margin-top: 20px;
    width: 200px !important;

    @media (max-width: 1290px) {
      width: auto !important;
    }
  }
}

.fp_rqg_frm,
.fp_rqg_crd-frm {
  width: 1500px;
  padding: 35px 50px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: rgba(34, 34, 34, 0.70);
  // backdrop-filter: blur(50px); //sometime not compatible to browsers

  .ant-form-item {
    margin-bottom: 15px !important;
  }

  .ant-input {
    border: 1px solid #3a393c !important;
  }

  &-tsno-inp {
    .ant-select.ant-select-in-form-item {
      display: flex;
    }
  }

  @include themify {
    // background-color: themed('background-opcity-40') !important;

    input,
    .ant-select-selector,
    &-reqInp {
      border-radius: 10px;
      background-color: themed('input-background-20') !important;
      color: themed('input-text-color') !important;
      text-align: left;

      &:focus {
        background: themed('input-background-20') !important;
        color: themed('input-text-color') !important;
      }
    }
  }

  input:disabled,
  .ant-select-disabled .ant-select-selector {
    background: rgba(255, 255, 255, 0.20) !important;
    color: $grey-1 !important;
  }

  &-reqHelp {
    margin-bottom: 10px;
    text-align: left;
    font-size: 10px !important;
  }
}

.fp_recording_browser_select {
  @include themify {

    input,
    .ant-select-selector,
    &-reqInp {
      border-radius: 10px;
      background-color: themed('input-background-20') !important;
      color: themed('input-text-color') !important;
      text-align: left;

      &:focus {
        background: themed('input-background-20') !important;
        color: themed('input-text-color') !important;
      }
    }
  }

  input:disabled,
  .ant-select-disabled .ant-select-selector {
    background: $grey-3 !important;
    color: $grey-1 !important;
  }
}

.fp_sub {
  display: block;
  text-align: center;
}

.fp_rqg_plan {
  margin-top: 10px;
  width: 200px;
  height: 130px;
  padding: 25px;
  border-radius: 25px;
  background-color: $black-40;
  border: 1px solid #663B38;
  backdrop-filter: blur(50px);

  &-ttl {
    color: $white;
    font-weight: 700;
    font-size: 15px;
    margin-block: 0 !important;
  }

  &-desc {
    // color: $dark-green;
    font-size: 23px;
    margin-block: 0 !important;
  }

  // &-free {
  //   .fp_rqg_plan-desc {
  //     color: $dark-green;
  //   }
  // }

  // &-pro {
  //   .fp_rqg_plan-desc {
  //     color: #DE4359;
  //     ;
  //   }
  // }

  // &-ent {
  //   .fp_rqg_plan-desc {
  //     color: #FFAA00;
  //   }
  // }

  &-rem {
    color: $grey-1 !important;
    font-size: 10px !important;
    margin-top: 10px !important;
  }

}

@media (max-width:1450px) {
  .fp_rqg_plan {
    width: 150px !important;

    &-upCard {
      width: 180px !important;
    }
  }
}

.fp_cd {
  &_inf {
    top: 20%;
    left: 3%;
    width: 55%;
    text-align: center;
  }

  &-frm {
    width: 650px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;

    .ant-modal-content {
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
      background-color: $black-gray !important;
      box-shadow: none;

      .ant-modal-footer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border: none;
      }
    }
  }
}

.fp_rqg_crd {
  &_inf {
    top: 20%;
    left: 3%;
    width: 55%;
    text-align: center;

    &-ftr-pay {
      display: flex;
      justify-content: space-between;
      width: 110px;
    }

    &-topup {
      left:20% !important;
    }
  }

  &-frm {
    width: 650px !important;

    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;

      .ant-modal-footer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border: none;
      }
    }

    &-inp {
      width: 226px;

      &-no {
        @include themify {
          border-radius: 20px;
          background-color: themed('input-background-20') !important;
          color: themed('input-text-color') !important;
          text-align: left;

          &:focus {
            background: themed('input-background-20') !important;
            color: themed('input-text-color') !important;
          }
        }
      }
    }

    .ant-card-bordered {
      border-radius: 20px !important;
    }

    .ant-card {
      background: transparent !important;
      margin-bottom: 10px;
      width: 200px;

      .ant-card-body {
        border-radius: 20px !important;
        background-color: #4e4444 !important;
        color: $white !important;
        text-align: left;
      }
    }

    .ant-switch-checked {
      background-color: #4e4444 !important;

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &-entpfrm {
    width: 100% !important;
  }

  &-ttl {
    color: $white;
    font-size: 20px;
    font-weight: 600;
    margin-block: 0;
  }

  &-sub {
    color: $white !important;
    font-size: 12px !important;
    font-weight: 400;
    margin-bottom: 30px;
  }

  &-desc {
    color: $grey-1 !important;
    font-size: 10px !important;
    text-align: left;
  }

  &-exp-dte {
    font-size: 10px !important;
  }

  &-prc {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    h1 {
      margin-bottom: 0;
    }
  }

  &-btn {
    margin-top: 10px;
    width: 25% !important;
    border: 2px solid $base-color-1 !important;

    @include themify {
      background-color: themed('input-background-20') !important;
      text-decoration: underline;
      color: $base-color-2 !important;

      &:hover {
        background-color: transparent;
        text-decoration: none;

        span {
          color: $base-color-3 !important;
        }
      }
    }
  }
}

.free {
  &-frm {
    .fp_rqg_plan-desc {
      color: $pink;
    }
  }

  .fp_rqg_plan-desc {
    color: $pink;
  }

  &-frm-modal {
    .ant-modal-content {
      border-top: 15px solid $pink !important;
      width: auto;
      .ant-modal-body {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        .fp_rqg_crd-frm {
          background-color: $transparent !important;
        }
      }

      .ant-table {
        background: transparent !important;
      }
    }

    .fp_rqg_plan-desc {
      color: $pink;
    }
  }

  .fp_rqg_plan-desc {
    color: $pink;
  }
}

.pro {
  &-frm {
    border-top: 15px solid $error-red;

    &-cdmodal {
      border-top: 20px solid $error-red !important;
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    }
    .fp_rqg_plan-desc {
      color: #DE4359;
    }
  }

  .fp_rqg_plan-desc {
    color: #DE4359;
  }
}

.entp {
  &-frm {
    .ant-modal-content {
      border-top: 15px solid $pink !important;
      width: auto;
      .ant-modal-body {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        .fp_rqg_crd-frm {
          background-color: $transparent !important;
        }
      }
    }

    .fp_rqg_plan-desc {
      color: $pink;
    }
  }

  .fp_rqg_plan-desc {
    color: $pink;
  }
}

.fp-modal-testcase {
  padding: 20px !important;
}

.fp_tstcs_mdl {
  margin-top: 10px;
  background-color: $white-20;
  padding: 20px;
  border-radius: 20px;
}

.fp_tstcs_rsltlst {
  overflow-y: auto;
}

.fp_tstcsID_div {
  text-align: center;
  padding-top: 10px;
}

.fp_tstscsID_coldv {
  display: flex;
  justify-content: center;
}

.fp_tstcsID_spn {
  padding-right: 5px;
}

.fp_tstcsID_img {
  padding-top: 5px;

  .fp_fdback_frm_dv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fp_fdback_frm_rw {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fp_fdback_cl {
    display: inline-block;
  }
}

.enterprise-form {
  margin-left: 0;
}

.error-popup {
  .ant-modal-content {
    background-color: $black-gray !important;
    box-shadow: none;
    border-radius: 20px;
    border: 1px solid #767676;
    .ant-modal-body {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  &-btn-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
}

.error-title {
  font-size: 18px;
  color: $white;
  font-weight: 700;
}

.fp_feedback-p {
  padding-bottom: 10px !important;

  &-bfrtxt {
    padding-bottom: 20px !important;
  }
}

.fp-stripe-card {
  background: transparent !important;
  margin-bottom: 10px;
  width: 200px;
  border: none;

  .ant-card-body {
    border-radius: 20px !important;
    background-color: #4e4444 !important;
    color: $white !important;
    text-align: left;
  }
}

.fp_disabled-radio {
  .ant-radio-disabled + span {
    color: $white !important;
  }
}

.test_data_modal_action_button_container {
  .ant-btn {
    padding: 0 !important;
    margin: 2px;
  }
}

.center_elements_inside_contenter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.test_data_model_content_container {
  line-height: 1.2;
  margin-bottom: 20px;

  span {
    font-size: 10px;
    color: gray;
  }
}

.fp_tst_rslt_modal_table {
  border-radius: 8px;
}

.test_data_model_content_span {
  font-size: 10px;
  color: gray;
}

.radio_container{
  justify-content: space-between;
  width: 100%;
  flex: none;
  margin-left: 20px;
}

.fp_test_case_result_skeleton_table {
  width: 100% !important;
  min-width: 100% !important
}

.new-testcase-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70%;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 20px;
  border-radius: 20px;
}

.inner-container {
  min-height: 100%;
}

.text {
  margin-right: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.ide-modal {
  margin-left: 240px;
}

.fp_dropdown_pre_req {
  color: $white;
}

.fp_dropdown_pre_req::selection {
  color: $white;
}

.fp_dropdown_pre_req::hover {
  color: $white;
}
/* Video container styles */
.video-container {
  /* Add your custom styles for the video container here */
  /* For example, you can set width, height, or other styles */
  width: 100%;
  max-width: 640px;
}

/* Placeholder container styles */
.placeholder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px; /* Customize the height as needed */
}

/* Placeholder icon styles */
.placeholder-icon {
  /* Add styles for the placeholder icon */
  font-size: 64px;
}

/* Placeholder text styles */
.placeholder-text {
  /* Add styles for the placeholder text */
  margin-top: 16px;
  font-size: 18px;
  color: #888; /* Customize the color as needed */
}

.fp-results-container {
  color: white !important;

  h2, h3 {
    color: white !important;
  }
}

.fp_extension_instructions_modal{
    position: relative;
    width: 1000px !important;
    height: 600px;

      .ant-modal-footer {
        border-top: 1px solid $model-color !important;
      }

      .fp_extension_btn{
        width: 114px;
      }

      .ant-modal-body {
        padding: 19px 46px 10px;
        background-color: $model-color;
        border-radius: 20px;
      }

      &_header{
        display: flex;
        align-items: center;
        color: $white;
        column-gap: 8px;
        margin-bottom: 20px;

        p {
          font-size: 16px !important;
          font-weight: 500 !important;
        }
      }

      &_footer{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }

      &_section1{
        text-align: center;
        margin-bottom: 20px;
      }

      &_section2 p{
        span {
          vertical-align: middle;
        }
        svg {
          margin: 0 8px;
        }
        margin-bottom: 16px;
      }

      &_section3 {
        padding: 13px 12px 15px 24px;
        row-gap: 23px;
        border-radius: 10px;
        border: 1px solid var(--white-20, rgba(255, 255, 255, 0.20));
        background: rgba(255, 255, 255, 0.10);

        p:first-child {
          margin-bottom: 13px;
        }

        a {
          color: $pink;
          text-decoration: none;
        }
      }
}

.testcase_copy_paste_container {
  background: #141414;
  width: 100%;
  height: 500px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .fp_btn {
    margin-bottom: 15;
    margin-top: 5;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: var(--Gradiant-hover-effect-1, linear-gradient(90deg, rgba(201, 222, 244, 0.30) 0%, rgba(228, 187, 192, 0.30) 50.52%, rgba(184, 164, 201, 0.30) 100%));

    .paste_icon {
      margin-right: 8px;
      vertical-align: text-bottom;
    }
  }
}
