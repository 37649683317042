// $base-color-1: var(--base-color-1);

$background_url: '/assets/background/default_background.svg';

$base-color-1: #537FA1;
$base-color-2: #FF8500;
$base-color-3: #FFAA00;
$base-color-4: rgba(255, 170, 0, 0.2);

// colors
$transparent: transparent;
$model-color: #222222;
$purple: #844EE0;
$green: #2EC6B2;
$red: #f00;
$dark-green: #058868;
$pink: #FF988E;

//grey variance
$grey-2: #7C7A7A;
$grey-1: #C4C4C4;
$grey-3: #343434;
$black-gray: #222222;

//black variance
$black-60: rgba(0, 0, 0, 0.6);
$black-50: rgba(0, 0, 0, 0.5);
$black-40: rgba(0, 0, 0, 0.4);
$black-20: rgba(0, 0, 0, 0.2);
$black: #000;

//button colors
$primaryTransparent:rgba(142, 89, 211, 0.3);
$primaryTransparent1: rgba(132, 78, 224, 0.5);

//white variance --- pending
$white-70: rgba(225, 225, 225, 0.7);
$white-60: rgba(225, 225, 225, 0.6);
$white-50: rgba(225, 225, 225, 0.5);
$white-40: rgba(225, 225, 225, 0.4);
$white-30: rgba(225, 225, 225, 0.3);
$white-20: rgba(225, 225, 225, 0.2);
$white-10: rgba(225, 225, 225, 0.1);
$white: #fff;

//indicator colors
$error-red: #ff4d4d;
$success-green: #4dff4d;

//state colors
$state-color-1: #93DEFF;
$state-color-2: #FF9C9C;
$state-color-3: #B2FF80;
$state-color-4: #FFD480;

//linear gradients
$linear-gradient-purple-green: linear-gradient(90deg, rgba(132, 78, 224, 1) 0%, rgba(46, 198, 178, 1) 100%);
$linear-gradient-purple-green-50: linear-gradient(90deg, rgba(132, 78, 224, 0.50) 0%, rgba(46, 198, 178, 0.50) 100%);
$linear-gradient-purple: linear-gradient(135deg, #693CDC 0%, rgba(112, 62, 221, 0.52) 52.08%);
$linear-gradient-green: linear-gradient(135deg, #21B48E 0%, rgba(34, 181, 142, 0.47) 52.08%);
$linear-gradient-pink: linear-gradient(135deg, #EC455C 0%, rgba(236, 69, 94, 0.56) 52.08%);
$linear-gradient-yellow: linear-gradient(135deg, #F2A447 0%, rgba(242, 164, 71, 0.39) 52.08%);
$hover-gradient: linear-gradient(90deg, rgba(201, 222, 244, 0.3) 0%, rgba(228, 187, 192, 0.3) 50.52%, rgba(184, 164, 201, 0.3) 100%);
$landing-color-4: linear-gradient(90deg, #C9DEF4 0%, #E4BBC0 50.52%, #B8A4C9 100%);
$landing-color-5: linear-gradient(90deg, rgba(201, 222, 244, 0.7) 0%, rgba(228, 187, 192, 0.7) 50.52%, rgba(184, 164, 201, 0.7) 100%);

//plain colors
$plan-green: rgba(34, 169, 134, 1);
$plan-pink: rgba(222, 67, 89, 1);
$plan-orange: rgba(255, 170, 0, 1);
$plan-purple: rgba(103, 59, 210, 1);
$disable-1: rgba(158, 158, 158, 1);


//box shadow colors
$box-shadow-back-btn: rgba(67, 0, 52, 0.05);

//fonts sizes
$heading-fontsize-1: 40px;
$heading-fontsize-2: 24px;
$heading-fontsize-3: 20px;
$heading-fontsize-4: 30px;

$large-text-fontsize-1: 18px;
$large-text-fontsize-2: 18px;

$medium-text-fontsize-1: 16px;
$medium-text-fontsize-2: 14px;

$regular-text-fontsize-1: 14px;
$regular-text-fontsize-2: 12px;

$small-text-fontsize: 10px;

//fonts weight
$weight-700: 700;

$themes: (
  light: (defult-text-color: $black,
    text-color-50: $black-50,
    background-opcity-40: $white-40,
    background-opcity-20: $white-20,
    background-menu-opacity-20: $black-20,
    background-content-opacity: $white-40,
    input-background-20: rgba(255, 255, 255, 0.20),
    input-text-color: $white,
    logo-color: $black ),
  dark: (defult-text-color: $white,
    text-color-50: $white-50,
    background-opcity-60: $black-60,
    background-opcity-40: $black-40,
    background-opcity-20: $black-20,
    background-menu-opacity-20: $white-20,
    background-content-opacity: $black-20,
    input-background-20: $white-20,
    input-text-color: $white,
    logo-color: $white ),
);