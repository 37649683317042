.fp_textinfo {
  &_container {
    @include themify {
      background-color: themed('background-menu-opacity-20');
    }

    margin: 0 11px 20px 11px;
    padding: 20px 0;
    border-radius: 10px;
    width: 98%;
    align-items: center !important;
  }

  &_icon {
    flex: 0 0 2%;
  }

  &_title {
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-bottom: 8px;
  }

  &_desc {
    font-size: 14px !important;
    font-weight: 400 !important;
    width: 100%;
  }
}

.col-form-item {
  width: 100%;

  .ant-form-item {
    margin-bottom: 1em;
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item-control-input {
    background-color: #544f4f;
    border-radius: 20px;
    height: 36px;
  }

  input,
  .ant-input[disabled] {
    padding-left: 15px;
    background-color: #544f4f;
    border-radius: 20px;
    width: 100%;
    border: none;
    color: #9e9e9e;
  }

  input {
    color: white;
  }

  .ant-input:focus {
    border-color: #544f4f !important;
    border: 2px solid #544f4f;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, .2);
    box-shadow: 0 0 0 2px rgba(105, 106, 106, 0.2);
  }

  span.ant-input-affix-wrapper.ant-input-password {
    background: #544f4f;
    border-radius: 20px;
    border: none !important;
  }

  .ant-input-affix-wrapper {
    background-color: #544f4f !important;
  }
}

.fp_frm_title{
  color: $white;
  font-size: 15px !important;
  padding-right: 10px !important;

  &-perunit{
    padding-top: 6px !important;
  }
  &-Professional{
    font-size: 25px !important;
    color: $plan-pink !important;
    font-weight: 800;
  }
  &-Enterprise{
    font-size: 25px !important;
    color: $plan-orange !important;
    font-weight: 800;
  }
  &-Pay{
    font-size: 25px !important;
    color: $plan-green !important;
    font-weight: 800;
  }
}

.fp_upcoming_feature {
  &_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px !important;

    p {
      font-size: 16px !important;
      font-weight: 300 !important;
      filter: brightness(0.9);
    }
  }
}