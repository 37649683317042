.pay_plan_container {
  min-height: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .fp_cmpnt_cntr {
    width: -webkit-fill-available;
    flex-direction: column;

    .ant-btn {
      padding: 0;
    }

    .pay_plan_tittle {
      color: $white !important;
      font-weight: 600 !important;
      font-size: 24px !important;
    }

    .pay_plan_sub_tittle {
      color: $white !important;
      font-weight: 400 !important;
      font-size: 14px !important;
    }
  }

  .billed_period {
    display: flex;
    align-items: center;
    margin-top: 20px;
    column-gap: 20px;

    .billed_tittle {
      font-size: 20px !important;
    }

    .ant-segmented {
      border-radius: 20px;

      .ant-segmented-group {
        border: 1px solid #663B38;
        border-radius: 20px;
        width: 200px;

        .ant-segmented-item {
          width: 50%;

          .ant-segmented-item-label:hover {
            color: #f4f5ed;
          }

          .ant-segmented-item-label {
            font-size: 13px;
            font-weight: 500;
          }
        }

        .ant-segmented-item-selected {
          background: rgba(255, 255, 255, 0.3);
          border-radius: 20px;
        }

        .ant-segmented-thumb {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  p {
    font-weight: normal;
  }

  .ant-row {
    margin: 20px 0;
    justify-content: center;
    align-items: baseline;
  }

  .ant-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ul {
    color: #fff;
    font-size: 12px !important;
    font-weight: 400;
  }

  li {
    margin-top: 8px;
    padding-left: 8px;
    list-style-image: url(../../../../components/Icons/check_icon.svg);
  }

  svg {
    margin-right: 8px;
  }

  .row_main {
    gap: 50px;

    .card_content {
      width: 380px;
      min-height: 500px;

      .pay_plan_button {
        width: 330px;
        height: 35px;
        box-shadow: 0px 4px 4px rgba(67, 0, 52, 0.05);
        border-radius: 20px;
        border-width: 0;
        margin-top: 10px;
        position: absolute;

        bottom: 10px;

        &.disable_active {
          background: #9E9E9E !important;
        }

        &.Explorer {
          color: $white;
          background: $linear-gradient-green;
        }

        &.Pathfinder {
          // color: $white;
          color: #222222;
          // background: $linear-gradient-pink;
          background: linear-gradient(90deg, #C9DEF4 0%, #E4BBC0 50.52%, #B8A4C9 100%);
        }

        &.Trailblazer {
          // color: $white;
          color: #222222;
          // background: $linear-gradient-yellow;
          background: linear-gradient(90deg, #C9DEF4 0%, #E4BBC0 50.52%, #B8A4C9 100%);
        }
      }

      .pay_plan_button:hover {
        color: #fff;
      }

      .content_title {
        font-weight: 600 !important;
        font-size: 24px !important;
      }

      .content_sub {
        font-weight: 500 !important;
        font-size: 14px !important;
        display: flex;
        align-items: center;
        margin-top: 10px;

        .content_price {
          margin-right: 8px;
          font-weight: 600 !important;
          font-size: 32px !important;

          &.Explorer {
            color: $plan-green;
          }

          &.Pathfinder {
            // color: $plan-pink;
            color: $pink;
          }

          &.Trailblazer {
            // color: $plan-orange;
            color: $pink;
          }
        }

        .disable_actived {
          color: $disable-1 !important;
        }
      }
    }

    .selected {
      &.Explorer {
        background: rgba(34, 169, 134, 0.2);
      }

      &.Pathfinder {
        background: $hover-gradient;
      }

      &.Trailblazer {
        background: $hover-gradient;
      }
    }

    .disable_active {
      background: rgba(34, 34, 34, 0.7);
      border-radius: 20px !important;

      &.dis {
        position: relative;
        background: rgba(34, 34, 34, 0.7);
        border-radius: 10px !important;
      }

      &.dis::before {
        background: #9E9E9E !important;
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 10px;
        padding-top: 10px;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }

    .border_gradient {
      position: relative;
      background: rgba(34, 34, 34, 0.7);
      border-radius: 10px !important;
    }

    .border_gradient::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 10px;
      padding-top: 10px;
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    .Explorer::before {
      background: $linear-gradient-green;
    }

    .Pathfinder::before {
      // background: $linear-gradient-pink;
      background: $pink;
    }

    .Trailblazer::before {
      // background: $linear-gradient-yellow;
      background: $pink;
    }

    .ant-card-bordered {
      border: 1px solid #663B38;
    }

    .disable_note {
      font-size: 12px !important;
      font-weight: 300 !important;
    }
  }

  .button_next {
    width: 162px;
    height: 35px;
  }
}