.user-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  .container {
    width: 100% !important;
    overflow-y: scroll;
    padding-bottom: 5em;

    .header {
      margin-left: 2em;

      h1 {
        color: white !important;
        font-size: 1.8em;
        margin-top: 0;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.2;
      }

      h4 {
        color: white !important;
        font-size: .9em;
        font-weight: 500;
      }
    }

    .profile-con {
      width: 80% !important;
      margin: auto !important;
      margin-top: 2em !important;

      @media (max-width:1200px) {
        width: 98% !important;
      }

      .profile-details-card {
        width: 60% !important;
        background-color: #242020;
        border-radius: 20px;
        border: 1px solid #613937;

        .profile-picture-uploader {
          position: relative;
          display: inline-block;
        }

        .user-image {
          display: block;
          width: 60px;
          border-radius: 50%;
          height: 60px;
          margin: 2em;

          @media (max-width:820px) {
            margin: 1em;
            margin-top: 2em
          }
        }

        .text-btn {
          width: 55px;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 45px;
          left: 58px;
          transform: translate(-50%, 0);
          background-color: rgba(34, 34, 34, 0.7);
          color: #fff;
          border-radius: 20px;
          cursor: pointer;
          transition: opacity 0.2s ease-in-out;
          font-size: 12px !important;
          text-align: center !important;

          .ant-btn {
            background-color: #544f4f;

            svg {
              margin-right: 5px;
            }
          }
        }

        .profile-picture-uploader:hover .text-btn {
          visibility: visible;
          opacity: 1;
        }

        .user-details {
          margin-top: 2em;
          margin-left: 1em;

          h5 {
            color: white !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 5px;
          }

          span {
            color: #747372;
            margin-top: 0px;
            line-height: 1;
            font-size: 13px;
            font-weight: 500;
          }
        }

        .user-form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90% !important;
          margin-left: 1em;
          margin-top: 1em;
        }

        .edit-btn-con {
          padding-right: 2.5em;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 0em;
          gap: 20px;

          .ant-btn {
            background-color: #544f4f;
            border: 3px solid #537fa1;

            span {
              font-size: 14px;
              font-weight: 800;
            }

            svg {
              margin-right: 5px;
            }
          }

          .ant-btn-round {
            padding: 4px 10px;
          }

          .save {
            width: 85px;
          }
        }
      }

      .current-plan-card {
        width: 35%;
        background-color: #242020;
        border-radius: 20px;
        border: 1px solid #613937;
        margin-left: 1.8em;
        padding: 1em 2em 2em 2em;

        .plan-header {
          h5 {
            color: white;
          }
        }

        .ant-btn {
          background-color: #787372;
          border: 3px solid #537fa1;
          display: flex;
          align-items: center;
          margin-top: 1em;

          span {
            font-size: 14px;
            font-weight: 600;
          }

          svg {
            margin-right: 5px;
          }
        }

        .ant-btn-round {
          padding: 4px 10px;
        }

        .plan-content {
          border: 1px solid #613937;
          background-color: #544f4f;
          width: 100%;
          padding: 2em;
          padding-left: 2.5em;
          border-radius: 20px;
          height: 100%;

          @media (max-width:820px) {
            padding: 1em;
            padding-left: 1em;
          }

          h5 {
            color: white;
            font-size: 14px;
          }

          .title {
            font-size: 20px;
            font-weight: 700;
            margin-top: 9px;
            margin-bottom: 9px;
          }

          .pay-as-go {
            color: #22a986;
          }

          .professional {
            color: #de4359;
          }

          .enterprise {
            color: #ffaa01;
          }
        }

      }

      .bank-details-card {
        width: 60%;
        background-color: #242020;
        border-radius: 20px;
        border: 1px solid #613937;
        padding: 1em 2em 2em 2em;

        @media (max-width:820px) {
          width: 100%;
        }

        .card-details {
          h5 {
            color: white !important;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 10px;
          }

          p {
            color: white !important;
            margin-top: 0px;
            line-height: 1;
            font-size: 12px !important;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }

        .card-details-danger {
          margin-top: 1em;
          color: red !important;
        }

        .card-view {
          border: 1px solid #613937;
          background-color: #544f4f;
          width: 100%;
          padding: 1em;
          border-radius: 20px;
          height: 100%;
          color: white !important;

          span {
            font-size: 16px !important;
          }

          .dots {
            font-size: 40px !important;
            margin-bottom: 10px !important;
            vertical-align: baseline;
            line-height: .4;
          }

          .date-row {
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            color: white !important;

            span {
              font-size: 12px !important;
            }
          }
        }

        .card-main {
          display: flex;
          justify-content: space-between;

          .card-container {
            width: 351px;
            background-color: #544f4f;
            border-radius: 20px;

            .ant-card-body {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 15px 25px;
            }
          }
        }

        .add-card-view {
          border: 1px solid #613937;
          background-color: #544f4f;
          width: 192px;
          border-radius: 20px;
          height: 46px;
          color: white !important;

          .ant-col-24 {
            gap: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .edit-btn-con {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 0em;
          align-items: end;

          .ant-btn {
            background-color: #544f4f;
            border: 3px solid #537fa1;

            span {
              font-size: 14px;
              font-weight: 800;
            }

            svg {
              margin-right: 5px;
            }
          }

          .ant-btn-round {
            padding: 4px 10px;
          }
        }

        .password-btn-con {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 0em;

          .ant-btn {
            width: 80px !important;
            margin-left: 1em;
            background-color: #544f4f;
            border: 3px solid #537fa1;

            span {
              font-size: 14px;
              font-weight: 800;
            }

            svg {
              margin-right: 5px;
            }
          }

          .ant-btn-round {
            padding: 4px 10px;
          }
        }

        .delete-btn-con {
          display: flex;
          justify-content: flex-start;
          padding-bottom: 0em;
          margin-top: 1em;

          .ant-btn {
            padding-left: 1.5em !important;
            padding-right: 1.5em !important;
            background-color: #c60000;
            border: none !important;
            height: 35px !important;

            span {
              font-size: 14px;
              font-weight: 800;
            }

            svg {
              margin-right: 5px;
            }
          }

          .ant-btn-round {
            padding: 4px 10px;
          }
        }

        .sub-header {
          font-size: 14px !important;
          font-weight: 500 !important;
          margin-top: .2em;
        }

        .password-form {
          width: 100%;
        }

        .password-con {
          width: 100%;

          .ant-form-item {
            margin-bottom: 1em;
          }

          .ant-form-item-label {
            padding-bottom: 0;
          }

          .ant-form-item-control-input {
            background-color: #544f4f;
            border-radius: 20px;
            height: 36px;
          }

          input,
          .ant-input[disabled] {
            padding-left: 15px;
            background-color: #544f4f !important;
            border-radius: 20px;
            width: 100%;
            border: none;
            color: #9e9e9e;
          }

          input {
            color: white;
          }

          .ant-input:focus {
            border-color: #544f4f !important;
            border: 2px solid #544f4f;
            outline: 0;
            -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, .2);
            box-shadow: 0 0 0 2px rgba(105, 106, 106, 0.2);
          }

          span.ant-input-affix-wrapper.ant-input-password {
            background: #544f4f;
            border-radius: 20px;
            border: none !important;
          }

          .ant-input-affix-wrapper {
            background-color: #544f4f !important;
          }
        }
      }
    }

  }
}

.delete-confirm-modal {
  .ant-modal-content {
    background-color: #242020;
    border-radius: 20px;
    border: 1px solid #613937;

    .delete-content{
      margin-bottom: 20px;
    }
  }

  .ant-modal-body {
    padding: 24px 24px 12px;
  }

  .ant-typography {
    color: $white !important;

  }

  .btn-main {
    justify-content: center;
    column-gap: 16px;

    .delete-btn {
      background-color: #c60000;
      border: none !important;
      height: 35px !important;
      color: $white;
    }

    .edit-btn {
      background-color: #544f4f;
      border: 3px solid $landing-color-4;
      color: $white;
      height: 35px !important;

      span {
        font-size: 14px;
        font-weight: 800;
      }

      svg {
        margin-right: 5px;
      }
    }
  }
}

.fp_profile_modal {
  .ant-modal-content {
    background: black;
    border-radius: 20px;
    border: #787372 solid 1px;
  }
}