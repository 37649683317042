.home_container {
  display: flex;
  height: -webkit-fill-available;
  min-height: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .fp_cmpnt_cntr {
    flex-direction: column;
    margin-bottom: 40px;

    .home_sub_tittle {
      color: $white !important;
      font-weight: 300 !important;
      font-size: 20px !important;
    }
  }

  .fp_cmpnt_dv {
    padding-bottom: 10px !important;
  }

  p {
    font-weight: normal;
  }

  .ant-row {
    justify-content: center;
    gap: 10px 30px;
  }

  .ant-col {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .ant-col-12 {
    margin-bottom: 30px;
  }

  .ant-ribbon-text {
    font-size: 8px;
    display: flex;
  }

  .card_container {
    filter: drop-shadow(1px 1px 3px rgba(255, 255, 255, 0.25));
    border-radius: 23px;
    border: none;
    -webkit-transition: all .3s;
    transition: all .3s;

    .card_content {
      background: rgba(255, 255, 255, 0.10);
      -webkit-transition: all .2s;
      transition: all .2s;
      border-radius: 20px;
      width: 200px;
      height: 150px;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .content_container {
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px 0;

        .icon_container {
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .details_container {
          display: flex;
          flex-direction: column;

          .content_tittle {
            font-size: 18px !important;
            font-weight: 300 !important;
            word-wrap: break-word !important;
          }

          .content_intro {
            font-size: 15px !important;
            word-wrap: break-word !important;
            font-weight: 400 !important;
          }
        }
      }
    }
  }

  .card_container:hover {
    // -webkit-box-shadow: 0px 0px 30px 1px rgba(46, 198, 178, 0.50) 100%;
    // -webkit-box-shadow: 0px 0px 30px 1px #FF988E;
    // box-shadow: 0px 0px 20px 1px #FF988E;
    // box-shadow: 0px 0px 10px 1px #ffffff80;
    // box-shadow: 0px 0px 20px 1px $purple;
    box-shadow: 0px 0px 10px 1px #2EC6B2;
    // box-shadow: 0px 0px 20px 1px rgba(46, 198, 178, 0.50) 100%;
    // box-shadow: 0px 0px 20px 1px rgba(255, 152, 142, 0.50) 100%;
    border-radius: 20px;
  }

  .card_content:hover {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.96);
        transform: scale(0.99);
    border-radius: 20px;
    // background: linear-gradient(90deg, rgba(132, 78, 224, 0.50) 0%, rgba(46, 198, 178, 0.50) 100%);
  }
}

.home_tooltip {
  .ant-tooltip-inner {
    width: 660px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    text-align: center !important;
    border-radius: 10px !important;
  }

  .ant-tooltip-open {
    display: flex !important;
    justify-content: center !important;
    align-self: center !important;
  }
}

.home_tooltip.ant-tooltip-placement-bottom {
  display: flex;
  justify-content: center;
}

.lock_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}