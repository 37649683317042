.fp_auto_rqg_frm,
.fp_rqg_crd-frm {
  width: 600px !important;
}

.fp_footnote_container {
  justify-content: left;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}

.fp_guide_modal{
  position: relative;
  width: 500px !important;

  .ant-modal-footer {
    border-top: 1px solid $model-color !important;
  }

  .fp_extension_btn{
    width: 114px;
  }

  .ant-modal-body {
    padding: 19px 46px 10px;
    background-color: $model-color;
    border-radius: 20px;
  }

  &_header{
    display: flex;
    align-items: center;
    color: $white;
    column-gap: 8px;
    margin-bottom: 20px;

    p {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  &_footer{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  &_section1{
    text-align: center;
    margin-bottom: 20px;
  }

  &_section2 p{
    span {
      vertical-align: middle;
    }
    svg {
      margin: 0 8px;
    }
    margin-bottom: 16px;
  }

  &_section3 {
    padding: 13px 12px 15px 24px;
    row-gap: 23px;
    border-radius: 10px;
    border: 1px solid var(--white-20, rgba(255, 255, 255, 0.20));
    background: rgba(255, 255, 255, 0.10);

    p:first-child {
      margin-bottom: 13px;
    }

    a {
      color: $pink;
      text-decoration: none;
    }
  }
}