.fp_type_select_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.fp_testcase_type {
  &_tile {
    &_container {
      padding-top: 30px;
      @media (max-width: 575px) {
        margin-bottom: 20px;
      }
    }
    &_empty {
      @media (max-width: 767px) {
        display: none;
      }
    }
    @include themify {
      background-color: themed('background-menu-opacity-20');
    }
    border-radius: 20px;
    padding: 0;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px !important;
    &:hover {
      @include themify {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
      }
    }
    &:active {
      @include themify {
        background-color: themed('background-menu-opacity-40');
      }
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.19);
      transform: translateY(2px);
    }
    &-active {
      background-color: $base-color-3 !important;
      box-shadow: 0 2px 4px 0 rgba(221, 211, 15, 0.2), 0 2px 5px 0 rgba(218, 199, 27, 0.19);
      transform: translateY(2px);
    }
  }
}

.fp_navigate_btn {
  width: 150px;
  @media (max-width: 767px) {
    width: 100%;
  }
  &_container {
    display: flex;
    align-items: flex-end;
    padding: 0 20px 20px 20px;
    flex-grow: 1;
    width: 100%;
    @media (max-width: 767px) {
      flex-grow: 0.2;
    }
  }
}

.fp_uplad_script_container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fp_testcase-upload {
  margin-top: 150px;
  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-data {
    span {
      .ant-upload {
        background-color: $base-color-2 !important;
      }
    }
  }
}

.fp_savescript_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fp_testcse_setting {
  &_form {
    padding-top: 50px;
    .ant-input-number {
      border-radius: 20px;
      background-color: $white-40;
      color: $white;
      .ant-input-number-handler-wrap {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        background-color: $white-60;
      }
    }
    .ant-slider-track {
      background-color: $base-color-1;
    }
    .ant-slider-handle {
      background-color: $base-color-1;
      border-color: $base-color-1;
    }
  }
  margin-top: 80px;
  @media (max-width: 1200px) {
    margin-top: 0;
  }
}

.fp_run_container {
  @media (max-width: 1200px) {
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.fp_run_form{
    padding-top: 20px;
    .ant-input-number {
      border-radius: 20px;
      background-color: $white-40;
      color: $white;
      .ant-input-number-handler-wrap {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        background-color: $white-60;
      }
    }
    .ant-slider-track {
      background-color: $base-color-1;
    }
    .ant-slider-handle {
      background-color: $base-color-1;
      border-color: $base-color-1;
    }
}

.fp_testcase_run_container {
  background-color: $white-20;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 155px;
  flex-direction: column;
  margin-bottom: 50px;
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 500px;
  }
  @media (max-width: 991px) {
    min-width: 500px;
    margin-bottom: 30px;
    width: 500px;
  }
  p{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.fp_testcase_check_status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  p{
    margin-left: 5px;
    color: #B2FF80 !important;
  }
}

.fp_testcase_save_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  button {
    margin-left: 10px;
  }
}

.fp_testcase_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // height: 100%;
}

.fp_testcase_test_status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .fp_test_status_without_color {
    margin-left: 10px;
    font-size: 20px !important;
  }
  .fp_test_status_success {
    margin-left: 12px;
    font-size: 24px !important;
    color: $state-color-3 !important;
  }
  .fp_test_status_error {
    margin-left: 12px;
    font-size: 24px !important;
    color: $state-color-2 !important;
  }
  .fp_test_status_pending {
    margin-left: 12px;
    font-size: 24px !important;
    color: $state-color-1 !important;
  }
  .fp_test_status_saved {
    margin-left: 12px;
    font-size: 24px !important;
    color: $state-color-4 !important;
  }
}

.margin_top_bottom_20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fp_testcase_summary_tile {
  @include themify {
    background-color: themed('background-menu-opacity-20');
    border-radius: 20px;
    padding: 20px 35px;
    color: themed('defult-text-color');
    box-shadow: 0 2px 4px 0 rgba(17, 17, 17, 0.2), 0 2px 5px 0 rgba(11, 11, 11, 0.19);
  }
  &_container {
    margin: 40px 0;
  }
  &_value {
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
    &_container {
      align-items: flex-end;
      margin: 15px 0;
    }
  }
  &_unit {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 3px;
  }
  &_title {
    font-size: 14px;
    font-weight: 500;
  }
}

.fp_testcase_summary_info {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 12px 0;
  .anticon {
    margin-right: 20px;
  }
}

.recharts-cartesian-axis-tick {
    font-size: 14px;
}
.recharts-legend-item {
  font-size: 0.75rem;
}
.recharts-label{
  font-size: 1rem;
  color: azure;
}

.fp_graph_tooltip {
  backdrop-filter: blur(50px); //sometime not compatible to browsers
  @include themify {
    background-color: themed('background-opcity-40') !important;
  }
  border-radius: 8px;
}

.code-editor {
  @include themify {
    background-color: themed('background-opcity-40') !important;
  }
  // box-shadow: 0 2px 4px 0 rgba(17, 17, 17, 0.2), 0 2px 5px 0 rgba(11, 11, 11, 0.19);
  border-radius: 10px;
  font-family: ui-monospace, SFMono-Regular, SF Mono,Consolas, Liberation Mono, Menlo, monospace !important;
  height: 65vh;
}
.fp_navigate_btn_scrpt{
  width: 120px !important;
}