.fp_lgn_ttl {
  @extend .fp_large-text-2 !optional;
  color: $white-50 !important;
  @include themify {
    color: themed('text-color-50') !important;
  }
}
.fp_lgn_ttl_update {
  color: $white !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  padding-bottom: 8px !important;
}
.fp_logo_color {
  color: $white;
  @include themify {
    color: themed('logo-color') !important;
  }
}
.fp_lgn_ggl{
  margin-top: 40px;
  background-color: white;
  border-radius: 20px;
  padding: 8px;
  text-align: center;
}
.fp_btn_ggl{
  border: none !important;
  outline: none !important;
  background-color: $white !important;
  color: #0048ff !important;
}
.fp_lgn_ggl_lnk{
  padding-left: 15px;
  outline: none !important;
  border: none !important;
}
.fp_lgn_rembrme{
  display: flex;
  justify-content: space-between;
}
.fp_lgn_area_dvde{
  text-align: center;
  padding: 15px;
}
.fp_lnk_to_frgt_psswrd{
  @extend .fp_medium-text-2;
  color: $base-color-2 !important;
  padding: 0 10px;
  &:hover {
    color: $base-color-3 !important;
  }
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: $white-40;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $white-40;
  border-color: $white;
}
.fp-google-login-button {
  margin-top: 40px;
  padding: 8px;
  width: 100% !important;
  border-radius: 30 !important;
  height: 45px;
  text-align: center;
  color: #537FA1 !important;
  background-color: white !important;
  .ant-image {
    margin-right: 15px;
  }
}