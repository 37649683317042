.fp_id_generator_page_container {
  padding: 50px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.10);
  margin: 40px;
  color: white !important;

  .ant-typography {
    color: white !important;
  }

  .fp_cli_command_block {
    border-radius: 4px;
    border: 1px solid var(--white-20, rgba(255, 255, 255, 0.20));
    background: rgba(255, 255, 255, 0.10);
    padding: 20px;
    color: white;
    margin-top: 10px;
  }

  .ul-list {
    color: var(--Main2, #FF988E);
    span {
      color: white
    }
  }

  .ul-links {
    color: var(--Main2, #FF988E);
    a {
      color: var(--Main2, #FF988E);
    }
  }
}