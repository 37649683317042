.fp_test_exec_suit {
  .fp_exec_suit_search {
    @include themify {
      height: 40px;
      min-width: 372px;
      border-radius: 10px;
      background-color: themed("background-menu-opacity-20");
      border: 1px $white solid;

      .ant-input-prefix {
        margin: 0 10px;
        color: $grey-1;
      }

      .ant-input {
        background: transparent;
        color: $white;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .fp_overall_container {
    margin-top: 30px;
    padding: 0 30px;
    background: rgba(255, 255, 255, 0.2);
    height: 60px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    &_left {
      display: flex;
      width: 50%;
      justify-content: space-between;
      align-items: center;
      color: $white;

      > span:first-child {
        font-size: 20px;
        font-weight: 500;
      }

      > span:nth-child(2) {
        font-size: 20px;
        color: $plan-green;
      }
    }
  }
}

.fp_test_exec_list_item {
  .fp_list_item {
    display: flex;
    height: 45px;
    padding: 0 30px !important;
    align-items: center;
    border-radius: 10px !important;
    background: rgba(255, 255, 255, 0.2) !important;

    button {
      border-radius: 10px !important;
    }

    &_module {
      display: flex;
      color: $white;

      div {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0 10px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 25px;
      }

      p{
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0 10px;
        border-radius: 25px;
      }

      span {
        margin: 0 4px;
      }
    }

    &_result {
      p {
        color: var(--plan-Green, #22a986);
        font-size: 16px !important;
        font-weight: 600 !important;
      }
    }

    .ant-checkbox-inner {
      background-color: $transparent;
      border-color: $white-50;
    }
  }

  &_action {
    align-items: center;
    column-gap: 12px;

    .btn_delete {
      background: #222;
      height: 32px !important;
      svg {
        vertical-align: text-top;
        color: $white;
        margin-right: 8px;
      }

      span {
        vertical-align: text-top;
        color: $white !important;
        font-size: 12px !important;
        font-weight: 300 !important;
      }

      &:hover {
        background: $purple;
      }
    }

    .btn_run {
      height: 32px !important;
    }
  }
}

.dash-icon{
 color: $white-20 !important;
 font-size: 16px !important;
 font-weight: 700 !important;
}

.fp_test_execution_modal {
  position: relative;
  left: 5%;

  .ant-modal-content {
    border-radius: 12px;
    background-color: $model-color;
    width: 600px;
  }

  .ant-modal-footer {
    padding: 0 50px 20px !important;
    text-align: center !important;
    border-top: 1px solid $model-color !important;

    button {
      min-width: 142px;
    }
  }

  &_header {
    color: $white;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 18px !important;
      font-weight: 600 !important;
    }
  }

  &_content {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }

    .ant-checkbox-inner {
      background: rgba(255, 255, 255, 0.2);
      border: none;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $pink;
      border-color: $pink;
    }
  }

  .ant-input {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  .ant-modal-body {
    padding: 24px 50px 0;
    background-color: $model-color;
    border-radius: 12px;
  }

  &_treeselect {
    .ant-select-tree-checkbox {
      &::after {
        border-color: $pink !important;
      }

      .ant-select-tree-checkbox-inner {
        // background-color: $pink;
        border-color: $pink;
      }

      &:hover {
        .ant-select-tree-checkbox-inner {
          background-color: $pink;
          border-color: $pink !important;
        }
      }

      &-checked {
        .ant-select-tree-checkbox-inner {
          background-color: $pink;
          border-color: $pink !important;
        }
      }
    }

    .ant-select-tree-node-content-wrapper:hover {
      background: #333232 !important;
      border: 1px solid $purple;
      border-radius: 10px;
      transition: none;
    }

    .ant-select-tree-list {
      background: #4e4e4e;
      color: $white;
    }
  }

  .ant-tree-select {
    .ant-select-arrow {
      color: $white !important;
    }

    .ant-select-selection-item {
      background: transparent;
      border-radius: 10px;

      .ant-select-selection-item-remove {
        color: $white !important;
      }
    }
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background: #444 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px;
    min-height: 40px;
    border-color: $purple;
    background: rgba(255, 255, 255, 0.2);
    color: $white;

    &:focus() {
      box-shadow: none;
    }
  }
}

.colored {
  .expandable {
    margin: 20px 0 8px !important;
  }

  .ant-modal-footer {
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 0px 0px 20px 20px;
  }
}

.fp-eas-button {
  // font-weight: $weight-700 !important;
  border-radius: 12px !important;
  height: 100px !important;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(67, 0, 52, 0.05);
  transition: none;
  border: 1.5px solid;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-device-button {
    // background: $transparent;
    border: $transparent !important;
    width: 100% !important;
    outline: "none";
    height: 120px !important;

    span {
      color: $white !important;
    }

    &:hover{
      background: $linear-gradient-purple-green;
    }

    &:focus {
      background: $linear-gradient-purple-green-50;
      color: $white !important;
    }

    &n:hover {
      color: $white !important;
    }

    &:disabled {
      color: $white !important;

      span {
        color: $white !important;
      }

      border-color: $white !important;
      background-color: $transparent !important;
      opacity: 0.5;
    }
  }
  &-btn-active{
    background: $linear-gradient-purple-green-50;
    color: $white !important;
  }
}


.aes-tooltip-container {
  margin-top: 30px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px !important;

  &-bg {
    background: $white-10;
    box-shadow: 0px 4px 4px 0px rgba(34, 34, 34, 0.25);
    height: 58px;
  }
  height: 28px;
  display: flex;
  padding: 9px 11px 9px 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.segmented-title {
  margin-top: 20px;
  color: $white;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}


.ant-picker-suffix{
  &:hover{
    background: blue !important;
  }
}



.form-col {
  display: flex;
  margin-top: 20px;
  margin-right: 40px; /* Adjust as needed */
}

.form-col:last-child {
  margin-right: 0; /* To remove margin for the last form-col */
}

.required-icon {

  .ant-form-item-label > label.ant-form-item-required{
    color: $white-70 !important;

    &:not(.ant-form-item-required-mark-optional)::before {
     color: $purple !important;

    }
  }
}

.modal-max-width{
  width: 1000px !important;
  left: 6% !important;
  top: 7% !important;
}

.close-modal-icon {
  // .ant-modal{
  //   margin-top: 200px !important;
  // }
  .ant-modal-close-x {
    margin-top: 10px !important;
  }
}

 .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background: $purple !important;
  }

  .ant-picker-clear{
    background: none !important;
   }

  .ant-picker-panel-container {
    background: #222222;

    .ant-picker-cell {
      color: $white-20 !important;
    }

    .ant-picker-cell-in-view {
      color: $white !important;


    }

    .ant-picker-content {
      thead tr th {
        color: $white !important;
      }
      tbody tr td{
        &:hover{
          .ant-picker-cell-inner{
            background: $linear-gradient-purple !important;
            &:hover{
              background: $linear-gradient-purple !important;
            }
          }
        }
      }
    }
    .ant-picker-time-panel-cell-selected{

      .ant-picker-time-panel-cell-inner{
        background: $purple !important;
      }
    }

    .ant-btn{
      background: $purple !important;
      color: $white !important;
      border: none !important;

      &:disabled {
        border-color: $white !important;
        background-color: $transparent !important;
        opacity: 0.5;
      }
    }

    .ant-picker-time-panel-cell-inner{
      color: $white !important;

      &:hover{
        background: $linear-gradient-purple !important;
      }

    }


    .ant-picker-footer {
      a {
        color: $purple !important;
      }
      .ant-btn{
        background: $purple !important;
        border: $purple !important;
        color: $white;
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
       border: 1px solid $purple !important;
    }
  }


.modal-margins{
    margin-top: 5% !important;
    margin-left: 37%;
}

.modal-min-top-margin{
    margin-top: 0% !important;
}

.modal-max-top-margin{
  margin-top: 7% !important;
}

.selection-menu {
  @include themify {
    .ant-select-selector {
      background: none !important;
      border: none !important;
    }
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none !important;
}

.ant-select{
  .ant-select-selector{
    margin-top: 5px !important;
  }
}

.ant-select-dropdown .ant-select-item-option-active{
  background: black !important;
}

.ant-select-item-option-content:hover{
  color: $white-20 !important;

}

.ant-select-item-option-content:focus{
  color: $white-40 !important;
}


.browser-results-container {
  width: 100%;
  .browser-result {
    display: flex;
    width: 120px;
    padding: 6px 8px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    color: white;
    text-align: center;

    img {
      width: 26.452px;
      height: 26.452px;
      flex-shrink: 0;
    }
  }

  .browser-result-pass {
    border: 1px solid var(--Indicator-Success, #22A986);
    background: var(--Indicator-Success, #22A986);
  }

  .browser-result-failed {
    border: 1px solid var(--Indicator-Danger, #E73550);
    background: var(--Indicator-Danger, #E73550);
  }

  .browser-btn-default-failed {
    border: 1px solid var(--Indicator-Danger, #E73550);
    background: var(--Background-Button, rgba(34, 34, 34, 0.40));
  }

  .browser-btn-default-passed {
    border: 1px solid var(--Indicator-Success, #22A986);
    background: var(--Background-Button, rgba(34, 34, 34, 0.40));
  }

  .browser-btn-selected {
    background: var(--Solid-primaryTransparent2, rgba(132, 78, 224, 0.50));
    border: 1px solid var(--Solid-primaryTransparent, rgba(142, 89, 211, 0.30));
  }
}

.fp_monitor_job_results_log_search {
  @include themify {
    height: 40px;
    border-radius: 10px;
    background-color: themed("background-menu-opacity-20");
    border: 1px $white solid;

    .ant-input-prefix {
      margin: 0 10px;
      color: $grey-1;
    }

    .ant-input {
      background: transparent;
      color: $white;
      font-size: 14px;
      font-weight: 700;
    }
  }
}