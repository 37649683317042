@import '~antd/dist/antd.css';
@import './var.scss';
@import './mixin.scss';
@import './antd-overrides.scss';
@import '../views/projects/projects.scss';
@import '../views/idGenerator/IdGeneratorPage.scss';
@import '../views/testcases/manualScript/manualScript.scss';
@import '../views/testcases/Record/recordScript.scss';
@import '../components/component.scss';
@import './footprint.scss';
@import '../views/home/Home.scss';
@import '../views/login/LoginPage.scss';
@import '../views/testcaseCreation/testcaseCreation.scss';
@import '../views/automationScriptGenerator/automationScriptGenerator.scss';
@import '../views/testcaseCreation/components/paymentPlan/PaymentPlan.scss';
@import '../views/views.scss';
@import '../views/qaAsService/QAService.scss';
@import '../views//testcaseCreation/components/supportView/SupportView.scss';
@import '../views/testExecutionSuit/testExecutionSuit.scss';
@import '../views/integration/Integration.scss';
@import '../views/onboard/onBoardingGuide.scss';

#root {
  // background-color: $base-color-1;
  height: 100vh;
  width: 100%;
  background-image: url('assets/midnight_background.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

#modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* semi-transparent black color */
  backdrop-filter: blur(5px); /* apply the blur effect */
  z-index: 999; /* adjust the z-index to make sure it overlays the modal */
  display: none; /* hide it by default */
}
