.integration-container {
  height: 120vh;
  backdrop-filter: blur(5px);

  hr {
    margin-bottom: 30px;
  }
}

.selector {
  margin-bottom: 10px;

  &-test-suit {
    width: 574px;
    height: 40px;
  }

  .ant-select-selector {
    height: 40px !important;
    background-color: #ffffff33 !important;
    border: 0 !important;
    border-radius: 12px !important;
    border-color: transparent;
    backdrop-filter: blur(8px);

    .ant-select-selection-search {
      input {
        margin-top: 5px;
      }
    }
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-selection-item {
    background-color: transparent;
    border: none;
    color: white;
    opacity: 1;

    div {
      display: flex;
      margin-top: 3px;
      gap: 10px;
      margin-left: 5px;

      img {
        height: 20px !important;
      }
    }
  }

}

.toot-selector {
  width: 574px;
  height: 40px;

  .ant-select-selection-placeholder {
    margin-top: 5px;
  }

  .ant-select-item-option-disabled {
    opacity: 0.2 !important;
  }
}

.link-box {
  background-color: #ffffff1a;
  border: 1px solid #ffffff33;
  border-radius: 4px;
  color: #ffffff;
  backdrop-filter: blur(8px);
  height: 40px;
  font-size: 16px;
  font-weight: 400;
}

.ant-select-dropdown {
  .ant-select-item-option-state {
    display: none;
  }

  .ant-select-item-option-active {
    background-color: transparent !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;

    .tool-image {
      background: linear-gradient(90deg, rgb(132, 78, 224) 0%, rgb(46, 198, 178) 100%);
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) div {
    color: white !important;
    opacity: 1;
  }

}

.integration-test-suit-checkbox {

  .ant-checkbox-inner {
    background-color: transparent;
    border-radius: 4px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(132, 78, 224, 0.7) !important;
    border-color: transparent;
  }
}

.ant-select-dropdown {
  padding: 10px !important;
}

.integration-tools {
  .rc-virtual-list-holder-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    gap: 12px;
    overflow-y: scroll;
    margin-right: 15px;

    .ant-select-item {
      padding: 0;
    }
  }

  .span {
    text-align: center;
  }

  .tool-image {
    display: flex;
    justify-content: center;
    height: 80px;
    width: 170px;
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 5px;

    img {
      margin: auto;
      max-width: 140px;
      max-height: 40px;
    }
  }
}

.integration-tools,
.test-suit {
  .rc-virtual-list-holder-inner::-webkit-scrollbar {
    width: 3px;
    overflow-x: overlay;
    scrollbar-width: thin;
    scrollbar-color: #555555 #f0f0f0 !important;
  }

  .rc-virtual-list-holder-inner::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.6) !important;
    display: block;
    width: 3px !important;
  }
}

.webhook-btn-container {
  display: flex;
}

.integration-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  margin-top: 30px;
}

.webhook-section {
  margin-top: 1.6rem;

  // button {
  //   width: 127px;
  // }
}

.webhook-text {
  font-size: 12px;
  font-weight: 300;
}

.webhook-btn {
  width: 218px;
  margin-top: 1rem;

  img {
    margin-right: 10px;
  }
}

.spin {
  margin-top: 30px;
  margin-left: 20px;
}

.option-tag {
  margin: 0;
  color: white;
  background-color: transparent;
  opacity: 0.6;
  font-size: 14px;
  padding-right: 0;
}

.generate-token-btn {
  width: 100% !important;
}