h2.ant-typography, h3.ant-typography {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 30;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin: 0 0 6px;
}
.frm-dscrptn {
  text-align: center;
  margin-top: 20px;
}
.form-row {
  justify-content: center;
  .frm-txt {
    top: 22px;
    font-family: Roboto;
    font-weight: normal;
    font-size: 10;
    line-height: 15px;
    letter-spacing: 0.01em;
    padding: 2px 0 15px 0;
  }
  .ant-form-item-label {
    padding: 0 0 4px;
  }
  .ant-upload {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-color: white;
  }
  .ant-upload .ant-upload-drag-container {
    padding: 0 60px 0 60px;
  }
  .fp_rqg_frm, .fp_rqg_crd-frm {
    padding: 0 50px;
    margin-left: 0;
  }
}
.spprt-vw-mdl {
  position: relative;
  left: 11%;
  .ant-modal-content {
    border-radius: 20px;
    border-top: 15px solid #DE4359;
    background-color: #222222;
    width: 448px;
  }
  .ant-modal-footer {
    border-top: 1px solid #222222 !important;
  }
  .mdl-k-btn {
    background-color: #537FA1;
    border-color: #537FA1;
    border-radius: 20px;
    width: 67.57px;
    height: 37px;
  }
  .ant-modal-body {
    padding: 24px 24px 0;
    background-color: #222222;
  }
  .ant-modal-footer {
    padding: 21px 16px 25px;
  }
}

.fp_supprt_titlerow {
  justify-content: center;
}